<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/inertia-vue3';

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false
});

const submit = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <main class="flex">

        <!-- Content -->
        <div class="w-full md:w-1/2">

            <div class="min-h-screen h-full flex flex-col justify-center">

                <div class="px-5 sm:px-6">
                    <div class="w-full max-w-md mx-auto">

                        <!-- Site branding -->
                        <div class="mb-6 flex justify-center items-center">
                            <!-- Logo -->
                            <router-link class="inline-flex text-blue-600 transition duration-150 ease-in-out" to="/" aria-label="Cruip">
<!--                                <img src="images/logo.png" class="w-80 mt-2">-->
                            </router-link>
                        </div>

                        <h1 class="h2 font-playfair-display text-slate-800 mb-12">Sign In</h1>

                        <!-- Form -->
                        <form @submit.prevent="submit">
                            <div class="space-y-4">
                                <div>
                                    <label class="block text-sm font-medium mb-1" for="email">Email <span class="text-rose-500">*</span></label>
                                    <TextInput id="email" type="email" class="form-input py-2 w-full" v-model="form.email" required autofocus autocomplete="username" />
                                </div>
                                <div>
                                    <label class="block text-sm font-medium mb-1" for="password">Password <span class="text-rose-500">*</span></label>
                                    <TextInput id="password" type="password" class="form-input py-2 w-full" v-model="form.password" required autocomplete="current-password" />
                                </div>
                            </div>
                            <div class="mt-6">
                                <label class="flex items-center">
                                    <Checkbox name="remember" v-model:checked="form.remember" />
                                    <span class="ml-2 text-sm text-gray-600">Remember me</span>
                                </label>
                            </div>
                            <div class="mt-6">

                                <PrimaryButton class="btn-sm w-full text-sm text-white bg-blue-600 hover:bg-blue-700 group" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">Log in <span class="tracking-normal text-blue-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                                </PrimaryButton>
                            </div>
                            <div class="text-center mt-5">
                                <Link v-if="canResetPassword" :href="route('password.request')" class="text-blue-600 hover:underline">Forgot your password?</Link>
                            </div>
                        </form>

                    </div>
                </div>

            </div>

        </div>

        <!-- Right side -->
        <div class="relative hidden md:block md:w-1/2 bg-slate-900" aria-hidden="true">

            <!-- Bg image -->
            <div class="absolute inset-0" data-aos="fade">
                <img class="opacity-10 w-full h-full object-cover" src="images/sign-in-bg.jpg" width="760" height="900" alt="Background" />
            </div>

            <!-- Quote -->
            <div class="min-h-screen h-full flex flex-col justify-center">
                <div class="px-5 sm:px-6">
                    <div class="w-full max-w-lg mx-auto">
                        <h2 class="h3 md:text-4xl font-playfair-display text-slate-100 mb-4">Certificate of Status</h2>
                        <div class="space-y-3">
                            <svg class="fill-blue-600" width="20" height="16" viewBox="0 0 20 16">
                                <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                            </svg>
                            <blockquote class="text-slate-400 italic">I got my certificate of status within the week. Thank you!</blockquote>
                        </div>
                        <div class="flex items-center mt-4 flex justify-center items-center">
                            <router-link to="#0">
                                <img class="rounded-full shrink-0 mr-3" src="images/customer-avatar-01.jpg" width="32" height="32" alt="Customer Avatar 05" />
                            </router-link>
                            <div class="font-medium">
                                <span class="text-slate-200">Jose M.</span>
                                <span class="text-slate-600"> · </span>
                                <span class="text-slate-500">Lawn Care and Maintenance</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </main>
</template>
